<template>
  <div>
    <h1>Ranglijst</h1>

    <item-list
      :rows="items"
      :loading=loading
      value=""
      @lazy="loadLazy" class="col-lg-8">
      <template v-slot="{row}">
        <td style="">
          {{row.rank}}
        </td>
        <td style="width: 100%">
          <profile-link :profile="row"></profile-link>
        </td>
        <td>{{row.points}}</td>
        <td v-if="multisport">{{row.sports.join(',')}}</td>
      </template>
    </item-list>
  </div>
</template>

<style lang="scss">
</style>

<script>
  import ProfileLink from '_sportvue/components/elements/ProfileLink';
  import ItemList from '_sportvue/components/ItemList';

  import LazyLoading from '@/mixins/LazyLoading.mixin';
  import api from '@/data/Backend';

  export default {
    data() {
      return {
        app_sports: (process.env.VUE_APP_SPORTS || '').split(','),

        sportfilters: {
          dart: true,
          billiard: true,
          tabletennis: true,
        },
      };
    },
    mounted() {
      this.getData();
    },
    beforeRouteUpdate(to, from, next) {
      next();
      this.getData();
    },
    computed: {
      multisport() {
        return this.app_sports.length > 1;
      },
    },
    methods: {
      toggleSport(sport) {
        this.sportfilters[sport] = !this.sportfilters[sport];

        const sports = Object.keys(this.sportfilters).filter(sport => this.sportfilters[sport] === true);

        if (sports.length === 0) {
          this.ranking = [];
          this.pagination.total = 0;
          return;
        }

        this.getData();
      },

      getData(newPage = undefined) {
        this.loading = true;
        this.loadData(api().getRankings(newPage || this.page, this.pagination.per_page));
      }
    },
    mixins: [LazyLoading],

    components: {
      ProfileLink,
      ItemList,
    },
  }
</script>
