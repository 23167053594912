var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("html", [
    _vm._m(0),
    _c("body", [
      _c("div", { attrs: { id: "app" } }, [
        _c(
          "div",
          {
            staticClass: "container-fluid",
            staticStyle: { "padding-left": "0" }
          },
          [_c("router-view")],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("head", [_c("title")])
  }
]
render._withStripped = true

export { render, staticRenderFns }