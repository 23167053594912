var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "form col-6 offset-3 h-100" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "email" } }, [_vm._v("Email")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.email,
            expression: "email"
          }
        ],
        staticClass: "form-control",
        class: { "is-invalid": _vm.errors["email"] },
        attrs: { id: "email", type: "email", name: "email" },
        domProps: { value: _vm.email },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.email = $event.target.value
          }
        }
      })
    ]),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "password" } }, [_vm._v("Password")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.password,
            expression: "password"
          }
        ],
        staticClass: "form-control",
        class: { "is-invalid": _vm.errors["password"] },
        attrs: { id: "password", type: "password", name: "password" },
        domProps: { value: _vm.password },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.password = $event.target.value
          }
        }
      })
    ]),
    _c(
      "button",
      {
        staticClass: "btn btn-primary",
        attrs: { type: "submit" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.submit($event)
          }
        }
      },
      [_vm._v("Login")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }