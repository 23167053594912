var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Ranglijst")]),
      _c("item-list", {
        staticClass: "col-lg-8",
        attrs: { rows: _vm.items, loading: _vm.loading, value: "" },
        on: { lazy: _vm.loadLazy },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("td", {}, [
                  _vm._v("\n        " + _vm._s(row.rank) + "\n      ")
                ]),
                _c(
                  "td",
                  { staticStyle: { width: "100%" } },
                  [_c("profile-link", { attrs: { profile: row } })],
                  1
                ),
                _c("td", [_vm._v(_vm._s(row.points))]),
                _vm.multisport
                  ? _c("td", [_vm._v(_vm._s(row.sports.join(",")))])
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }