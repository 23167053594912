import Vue from 'vue';
import App from '@/App.vue';
import Component from 'vue-class-component';
import VTooltip from 'v-tooltip';

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);

import router from './router';
import Sportvue from 'sportvue/src/index';
import i18n from '@/lang/i18n';
// @ts-ignore
import * as VueGoogleMaps from 'vue2-google-maps';


import ('@/scss/' + process.env.VUE_APP_SCSS || 'app.scss');

import ProfileLink from 'sportvue/src/components/elements/ProfileLink.vue';
import {timeAgo} from 'sportvue/src/filters/dateTime';
import 'mapbox-gl/dist/mapbox-gl.css';

Vue.config.productionTip = false;
Vue.use(Sportvue, {router});
Vue.use(VTooltip);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS,
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
});

Vue.component('profile-link', ProfileLink);
Vue.filter('timeAgo',  timeAgo);

Vue.filter('username', (value: any) => {
  return `${value.first_name} ${value.last_name}`;
});

Vue.filter('profilename', (value: any) => {
  return typeof value.user === 'undefined' ? '-' : `${value.user.first_name} ${value.user.last_name}`;
});

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
