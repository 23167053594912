<template>
    <form class="form col-6 offset-3 h-100">
      <div class="form-group">
        <label for="email">Email</label>
        <input id="email" type="email" name="email" v-model="email" class="form-control" :class="{'is-invalid': errors['email']}">

      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input id="password" type="password" name="password" v-model="password" class="form-control" :class="{'is-invalid': errors['password']}">
      </div>
      <button type="submit" @click.prevent="submit" class="btn btn-primary">Login</button>
    </form>
</template>

<script>
  import api from '@/data/Backend';

  export default {
    data() {
      return {
        email: '',
        password: '',
        errors: {
          email: false,
          password: false
        }
      }
    },
    methods: {
      submit() {
        api().login(this.email, this.password).then(token => {
          localStorage.setItem('api_token', token);
          this.$router.push({ name: 'home' });
        }).catch((result) => {
          this.errors = result.response.data.errors;
        });
      }
    }
  }
</script>

<style lang="scss">

</style>
