import {AxiosInstance} from 'axios';
import {Backend} from './Backend';
import {DashboardStats} from '@/models/DashboardStats';
import {Profile} from '@/models/Profile';
import CollectionResponse from './CollectionResponse';

const user2profile = (user: any): Profile => {
    return {
        user,
        sports: ['table_tennis'],
        ...user,
    };
};

const location2canteen = (location: any) => {
    return {
        location,
        ...location,
        spots: location.spots.map((spot: any) => {
            return {
                ...spot,
                sport: 'table_tennis',
            };
        }),
    };
};

const convertsession = (session: any) => {
    return {
        ...session,
        organizer: user2profile(session.organizer),
        sport: 'table_tennis',
        started_at: session.starts_at,
        teams: (session.teams || []).map((team: any) => {
            const statusses = {
                '-1': 'unknown',
                '0': 'lost',
                '1': 'draw',
                '2': 'won',
            };

            return {
                ...team,
                statusText: statusses[team.status] || 'unknown',
                profiles: team.users.map(user2profile),
            };
        }),
        games: (session.scores || []).map((score: any) => {
            return  {
                id: score.game,
                finished_at: null,
                latest_scores: [],
                total: score.scores,
                remaining: [],
            };
        }),
    };
};

export class PpbBackend implements Backend {
    private api: AxiosInstance;
    private adminApi: AxiosInstance;

    constructor(api: AxiosInstance, adminApi: AxiosInstance) {
        this.api = api;
        this.adminApi = adminApi;
    }

    public login(email: string, password: string): Promise<string> {
        return this.api.post('/login', {email, password}).then((response) => {
            return response.data.api_token.token;
        });
    }

    public getStats(): Promise<DashboardStats> {
        return this.api.get('/statistics/counts', {
            params: {
                all: true,
            },
        }).then(({data}) => {
            return {
                user_count: data.statistics.users_count,
                users_active_count: data.statistics.users_active_count,
                users_today_count: data.statistics.users_today_count,
                locations_count: data.statistics.locations_count,
                spots_count: data.statistics.spot_count,
                sessions_count: data.statistics.session_count,
                sessions_today_count: data.statistics.sessions_created_today_count,
            };
        });
    }

    public getSportLocations(page = 1, limit = 50, query?: string): Promise<CollectionResponse> {
        const include = [
            'spots',
        ].join(',');

        return this.adminApi.get(`/locations`, {
            params: {include, query, page, limit},
        }).then(({data}) => {
            return {
                items: data.locations.map(location2canteen),
                pagination: data.meta.pagination,
            };
        });
    }

    public getSportLocation(id: number): Promise<any> {
        const include = [
            'spots',
            'opening_hours',
        ].join(',');

        return this.adminApi.get(`/locations/${id}`, {
            params: {include},
        }).then(({data}) => {
            return data.location;
        });
    }

    public getProfiles(page = 1, limit = 50, query?: string): Promise<CollectionResponse> {
        return this.adminApi.get(`/users`, {
            params: {
                page,
                limit,
                query,
                order: 'created_at.desc',
            },
        }).then((response) => {
            return {
                items: response.data.users.map(user2profile),
                pagination: response.data.meta.pagination,
            };
        });
    }

    public getProfile(id: number): Promise<Profile> {
        const include = [
            'codes',
            'locations',
            'user',
            'ranking',
            'teams',
            // 'vitality',
        ].join(',');

        return this.adminApi.get(`/profiles/${id}`, {
            params: {
                include,
            },
        }).then(({data}) => {
            const p = data.profile as Profile;

            p.unapproved_sessions_count = data.profile.teams.reduce((memo: number, item: any) => {
                return item.approved === true ? memo : memo + 1;
            }, 0);

            return p;
        });
    }

    public getRankings(page = 1, limit = 50): Promise<CollectionResponse> {
        return this.api.get(`/rankings`, {
            params: {
                page,
                limit,
                include: 'user',
            },
        }).then((response) => {
            return {
                items: response.data.users.map(user2profile),
                pagination: response.data.meta.pagination,
            };
        });
    }

    public getSessions(page = 1, limit = 50): Promise<CollectionResponse> {
        const include = [
            'organizer.user',
            'location',
        ].join(',');


        return this.adminApi.get(`/sessions`, {
            params: {
                page,
                limit,
                include,
                order: 'created_at|desc',
            }}).then(({data}) => {
            return {
                items: data.sessions.map(convertsession),
                pagination: data.meta.pagination,
            };
        });
    }

    public getSession(id: number): Promise<any> {
        const include = [
            'spot',
            'participants.user',
            'organizer.user',
            'location',
            'accepted_users',
            'paid_users',
            'teams.users',
            'scores',
        ].join(',');

        return this.adminApi.get(`/sessions/${id}`, {
            params: {
                include,
            },
        }).then(({data}) => {
            return convertsession(data.session);
        });
    }

    public getSessionNotifications(id: number): Promise<CollectionResponse> {
        const include = [
            'profile.user',
        ].join(',');

        return this.api.get(`/sessions/${id}/notifications`, {
            params: {
                include,
            },
        }).then((response) => {
            return {
                items: response.data.notifications,
                pagination: response.data.meta.pagination,
            };
        });
    }

    public getStickers(sent: boolean, page: number, limit: number): Promise<CollectionResponse> {
        return this.adminApi.get('/stickers?send=' + sent).then(({data}) => {
            return {
                items: data.stickers.map((sticker: any) => {
                    return {
                        id: sticker.id,
                        city: sticker.city,
                        postal_code: sticker.postal_code,
                        street: sticker.street,
                        house_no: sticker.house_no,
                        send_at: sticker.send_at,
                        amount: sticker.amount,
                        created_at: sticker.created_at,
                        location_name: sticker.location_name,
                        requester_name: sticker.requester_name,
                        requester_email: sticker.requester_email,
                    };
                }),
                pagination: data.meta.pagination,
            };
        });
    }

    public setStickerSent(id: number, page: number, limit: number): Promise<CollectionResponse> {
        return this.adminApi.patch(`/stickers/${id}`, {sent: true}).then(() => {
            return this.getStickers(false, page, limit);
        });
    }

    public getSpots(page: number, limit: number, query?: string): Promise<CollectionResponse> {
        return this.adminApi.get(`/spots`, {
            params: {
                page,
                limit,
            },
        }).then((response) => {
            return {
                items: response.data.spots,
                pagination: response.data.meta.pagination,
            };
        });
    }
}
