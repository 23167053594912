import Vue from 'vue';
import Component from 'vue-class-component';
import CollectionResponse from '@/data/CollectionResponse';

@Component
export default class LazyLoadingMixin extends Vue {
  public items: any[] = [];
  public loading = false;

  public pagination = {
    current_page: 1,
    per_page: 50,
    total: 0,
    links: {
      next: undefined,
    },
  };

  get page(): number {
    // @ts-ignore
    return parseInt(this.$route.query.page as string, 10) || this.pagination.current_page;
  }

  public loadData(prom: Promise<CollectionResponse>) {
    return prom.then((response) => {
      this.items = [...this.items, ...response.items];
      this.pagination = response.pagination;
      return this.items;
    }).catch((e) => {
      console.error(e);
    }).finally(() => {
      this.loading = false;
    });
  }

  public loadLazy() {
      if (this.loading || !this.pagination.links.next) {
        return;
      }

      // @ts-ignore
      this.getData(this.page + 1);
    }

}
