import {DashboardStats} from '@/models/DashboardStats';
import {adminBackend, backend, sportId} from '@/api';
import router from '@/router';
import {Kbbackend} from '@/data/kbbackend';
import {PpbBackend} from '@/data/PpbBackend';
import {Profile} from '@/models/Profile';
import CollectionResponse from './CollectionResponse';

export interface Backend {
    getStats(): Promise<DashboardStats>;

    getSportLocations(page: number, limit: number, query?: string): Promise<CollectionResponse>;

    getSpots(page: number, limit: number, query?: string): Promise<CollectionResponse>;

    getProfiles(page: number, limit: number, query?: string): Promise<CollectionResponse>;

    getProfile(id: number): Promise<Profile>;

    getRankings(page: number, limit: number): Promise<CollectionResponse>;

    getSessions(page: number, limit: number): Promise<CollectionResponse>;

    getSession(id: number): Promise<any>;

    getSessionNotifications(id: number): Promise<CollectionResponse>;

    getStickers(sent: boolean, page: number, limit: number): Promise<CollectionResponse>;

    setStickerSent(id: number, page: number, limit: number): Promise<CollectionResponse>;

    login(username: string, password: string): Promise<string>;
}

export default function factory(): Backend {
    if (process.env.VUE_APP_BACKEND_TYPE === 'PPB') {
        return new PpbBackend(backend(router), adminBackend(router));
    }

    return new Kbbackend(backend(router), adminBackend(router), sportId(router));
}

