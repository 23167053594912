import Vue from 'vue';
import Router from 'vue-router';
import LoginComponent from '@/components/LoginComponent.vue';
import RankingComponent from '@/components/RankingComponent.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/components/DashboardComponent.vue'),
            props: {
                logo: require(process.env.VUE_APP_LOGO),
            },
            children: [
                {
                    path: '/',
                    name: 'home',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
                },
                {
                    path: '/users',
                    name: 'users',
                    component: () => import(/* webpackChunkName: "dashboard" */
                        '@/views/Users.vue'),
                },
                {
                    path: '/users/:id',
                    name: 'profiles.show',
                    component: () => import(/* webpackChunkName: "dashboard" */
                        '@/views/User.vue'),
                },

                {
                    path: '/sessions',
                    name: 'sessions',
                    component: () => import(/* webpackChunkName: "dashboard" */
                        '@/views/Sessions.vue'),
                },
                {
                    path: '/sessions/:id',
                    name: 'sessions.show',
                    component: () => import(/* webpackChunkName: "dashboard" */'@/views/Session.vue'),
                },
                {
                    path: '/locations',
                    name: 'locations',
                    component: () => import(/* webpackChunkName: "dashboard" */
                        '@/components/LocationsWrapper.vue'),
                    children: [
                        {
                            path: '',
                            name: 'locations.index',
                            component: () => import(/* webpackChunkName: "dashboard" */'@/views/Locations.vue'),
                        },
                        {
                            path: 'spots',
                            name: 'spots.index',
                            component: () => import(/* webpackChunkName: "dashboard" */
                                '@/components/SpotsComponent.vue'),
                        },
                    ],
                },
                {
                    path: '/locations/:id',
                    name: 'locations.show',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Location.vue'),
                },
                {
                    path: 'spots/:id',
                    name: 'spots.show',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Spot.vue'),
                },
                {
                    path: '/stickers',
                    name: 'stickers',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Stickers.vue'),
                    children: [
                        {
                            path: 'batches',
                            name: 'stickers.batches',
                            meta: {
                                title: 'Batches',
                            },
                            component: () => import(/* webpackChunkName: "dashboard" */
                                '@/components/BatchesComponent.vue'),
                        },
                        {
                            path: ':type',
                            name: 'stickers.index',
                            meta: {
                                title: 'Stickers',
                            },
                            component: () => import(/* webpackChunkName: "dashboard" */
                                '@/components/StickersComponent.vue'),
                        },
                        {
                            path: '/batches/create',
                            name: 'stickers.batches.create',
                            meta: {
                                title: 'Batches',
                            },
                            component: () => import(/* webpackChunkName: "dashboard" */
                                '@/components/CreateBatchComponent.vue'),
                        },
                    ],
                },
                {path: '/ranking', component: RankingComponent, name: 'ranking'},
            ],
        },
        {path: '/login', component: LoginComponent, name: 'login'},
    ],
});
