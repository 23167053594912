import {AxiosInstance} from 'axios';
import {Backend} from './Backend';
import {DashboardStats} from '@/models/DashboardStats';
import CollectionResponse from './CollectionResponse';

const location2canteen = (location: any) => {
    if (!location) {
        return {};
    }

    return {
        ...location,
        ...location.location,
    };
};

export class Kbbackend implements Backend {
    private api: AxiosInstance;
    private adminApi: AxiosInstance;
    private sportId: AxiosInstance;

    constructor(api: AxiosInstance, adminApi: AxiosInstance, sportId: AxiosInstance) {
        this.api = api;
        this.adminApi = adminApi;
        this.sportId = sportId;
    }

    public getStats(): Promise<DashboardStats> {
        return this.adminApi.get('/dashboard').then(({data}) => {
            return {
                user_count: data.user_count,
                users_active_count: data.users_active_count,
                users_today_count: data.users_today_count,
                sessions_count: data.session_count,
                sessions_today_count: data.sessions_created_today_count,

                locations_count: data.canteens_count,
                spots_count: data.spots_count,
                errors_count: data.errors_count,
            };
        });
    }

    public getSportLocations(page = 1, limit = 50, query?: string): Promise<CollectionResponse> {
        const include = [
            'spots',
        ].join(',');

        return this.api.get(`/canteens`, {
            params: {include, query, page, limit},
        }).then((response) => {
            return {
                items: response.data.canteens.map(location2canteen),
                pagination: response.data.meta.pagination,
            };
        });
    }

    public getSportLocation(id: number): Promise<any> {
        const include = [
            'spots',
        ].join(',');

        return this.api.get(`/canteens/${id}`, {
            params: {include},
        }).then(({data}) => {
            return location2canteen(data);
        });
    }

    public getStickers(sent: boolean, page: number, limit: number): Promise<CollectionResponse> {
        return this.adminApi.get(`/qr-request`, {
            params: {
                sent,
            },
        }).then(({data}) => {
            return {
                items: data.requests,
                pagination: {},
            };
        });
    }

    public setStickerSent(id: number, page: number, limit: number): Promise<CollectionResponse> {
        return this.adminApi.patch(`/stickers/${id}`, {sent: true}).then(() => {
            return this.getStickers(false, page, limit);
        });
    }

    public getProfiles(page = 1, limit = 50): Promise<CollectionResponse> {
        return this.adminApi.get(`/profiles`, {
            params: {
                include: 'user',
                page,
                limit,
            },
        }).then((response) => {
            return {
                items: response.data.profiles,
                pagination: response.data.meta.pagination,
            };
        });
    }

    public getProfile(id: number): Promise<any> {
        return this.adminApi.get(`/profiles/${id}`, {
            params: {
                include: 'user',
            },
        }).then(({data}) => {
            return data;
        });
    }

    public getRankings(page = 1, limit = 50): Promise<CollectionResponse> {
        return this.api.get(`/ranking`, {
            params: {
                page,
                limit,
                include: 'user',
            },
        }).then((response) => {
            return {
                items: response.data.ranking_profiles,
                pagination: response.data.meta.pagination,
            };
        });
    }

    public getSessions(page = 1, limit = 50): Promise<CollectionResponse> {
        const include = [
            'organizer.user',
            'spot.canteen',
        ].join(',');

        return this.adminApi.get(`/sessions`, {
            params: {
                page,
                limit,
                include,
            }}).then((response) => {
            return {
                items: response.data.sessions.map((session: any) => {
                    return {
                        ...session,
                        location: location2canteen(session.spot.canteen),
                    };
                }),
                pagination: response.data.meta.pagination,
            };
        });
    }

    public getSession(id: number): Promise<any> {
        const include = [
            'organizer.user',
            'participants.user',
            'teams.profiles.user',
            'games',
            'games.scores',
            'wins_per_team',
            'spot.canteen',
        ].join(',');

        return this.adminApi.get(`/sessions/${id}`, {
            params: {
                include,
            },
        }).then(({data}) => {
            return {
                ...data,
                location: location2canteen(data.spot.canteen),
            };
        });
    }

    public getSessionNotifications(id: number): Promise<CollectionResponse> {
        const include = [
            'profile.user',
        ].join(',');

        return this.api.get(`/sessions/${id}/notifications`, {
            params: {
                include,
            },
        }).then((response) => {
            return {
                items: response.data.notifications,
                pagination: response.data.meta.pagination,
            };
        });
    }

    public login(username: string, password: string): Promise<string> {
        return this.sportId.post('/auth/login', {email: username, password}).then((response) => {
            return response.data.api_token.token;
        });
    }

    public getSpots(page: number, limit: number, query?: string): Promise<CollectionResponse> {
        const include = [
            'canteen.location',
            'session_count',
        ].join(',');

        return this.adminApi.get(`/spots`, {
            params: {
                include,
                page,
                limit,
                active: query === 'active',
                temporary: query === 'temporary',
            },
        }).then(({data}) => {
            return {
                items: data.spots.map((spot: any) => {
                    return {
                        ...spot,
                        canteen: location2canteen(spot.canteen),
                    };
                }),
                pagination: data.meta.pagination,
            };
        });
    }
}
