import axios, {AxiosInstance} from 'axios';
import {VueRouter} from 'vue-router/types/router';

export function makeApi(rootUrl: string, router?: VueRouter): AxiosInstance {
  const api = axios.create({
    baseURL: rootUrl,
  });

  api.defaults.headers.common.Accept = 'application/json';
  api.defaults.headers.common['X-Sport'] = 'table_tennis';

  api.interceptors.request.use((config) => {
    const apiToken = localStorage.getItem('api_token');

    config.headers['X-Sport'] = 'table_tennis';

    if (apiToken) {
      config.headers.Authorization = `Bearer ${apiToken}`;
    }

    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  api.interceptors.response.use((response) => {
    return response;
  }, (response) => {
    if (response.response && response.response.status === 401) {
      localStorage.removeItem('api_token');
      if (router) {
        router.push({ name: 'login' });
      }
    }

    return Promise.reject(response);
  });

  return api;
}

export const sportId = (router?: VueRouter) => makeApi(process.env.VUE_APP_SPORTID_API, router);
export const backend = (router?: VueRouter) => makeApi(process.env.VUE_APP_SPORTCREDITS_API, router);
export const adminBackend = (router?: VueRouter) => makeApi(process.env.VUE_APP_SPORTCREDITS_ADMIN_API, router);
